import React from 'react';
import { graphql, withPrefix } from 'gatsby';
import Img from 'gatsby-image';
import SEO from '../../components/SEO';
import Layout from '../../layouts/index';

const Products = (props) => {
  const products = props?.data.allFile.edges;
  const productCategories = props?.data.allProductCategoriesJson.edges;

  const getFluidImage = (search) => {
    return products.find((productImage) => (
      productImage.node.childImageSharp.fluid.originalName === search
    ))
  }

  return (
    <Layout bodyClass="page-products">
      <SEO title="Produkte" />
      <div className="intro intro-small">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1>Sortiment</h1>
              <p>
                Hier sehen sie eine Auswahl unserer Produkte
              </p>
            </div>
          </div>
        </div>
      </div>

      {productCategories.map(({ node }) => (
      <div className="product-category container pt-3 pb-3 pt-md-3">
        <h2 className="">{ node.title }</h2>
        <p>{ node.description }</p>
        <div className="row d-flex flex-wrap justify-content-around">
          {node.images.map((imageUrl) => (
            <div key={node.id} className="card-lifted col-md-5 mb-3 mb-md-8">
              <Img
                className="card-image"
                fluid={getFluidImage(imageUrl).node.childImageSharp.fluid}
              />
            </div>
          ))}
        </div>
      </div>
      ))}

    </Layout>
  );
};

export const query = graphql`
  query ProductImagesQuery {
  
    allFile(
      filter: {
        sourceInstanceName: {eq: "products"}, 
        extension: {regex: "/jpeg|jpg|png|gif/"}
      }
    ) {
      edges {
        node {
          id
          childImageSharp {
            fluid(quality: 70, maxWidth: 512) {
              ...GatsbyImageSharpFluid
              src,
              originalName
            }
          }
        }
      }
    }

    allProductCategoriesJson {
      edges {
        node {
          id
          title
          description
          images
        }
      }
    }

  }
`

export default Products;
